import React from 'react';
import Liqpay from '../components/Liqpay/';
import Layout from '../components/layout';
import AuthUserContext from '../components/Session/AuthUserContext';
import withAuthorization from '../components/Session/withAuthorization';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';

const AccountPageBase = props => {
  return (
    <React.Fragment>
      <AuthUserContext.Consumer>
        {({ authUser, userData = {} }) => (
          <div className="jumbotron">
            <h2>{authUser.displayName || authUser.email}</h2>
            <h3>Баланс: {userData.money} грн</h3>
            {userData.abonement && (
              <h3>Абонемент до {moment(userData.abonement.toDate()).format('DD MMM YYYY')}</h3>
            )}
            <hr className="my-4" />
            <Liqpay authUser={authUser} />
          </div>
        )}
      </AuthUserContext.Consumer>
    </React.Fragment>
  );
};

const authCondition = authUser => !!authUser;

const AccountPage = withAuthorization(authCondition)(AccountPageBase);

export default () => (
  <Layout>
    <AccountPage />
  </Layout>
);
