import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import _debounce from 'lodash/debounce';
import { FaFacebook, FaPhone, FaMailBulk } from 'react-icons/fa';
import { Link } from 'gatsby';
import * as routes from '../../constants/routes';
import { withPrefix } from 'gatsby';
import { withFirebase } from '../Firebase/FirebaseContext';
import { withTrainings } from '../../BuisenessLogic/TrainingsContext';
import AuthUserContext from '../Session/AuthUserContext';
import _get from 'lodash/get';
import Button from '@material-ui/core/Button';
import moment from 'moment';

const IndexWrapper = ({ authUser, ...props }) => {
  return (
    <AuthUserContext.Consumer>
      {({ authUser, userData }) => <Index authUser={authUser} userData={userData} {...props} />}
    </AuthUserContext.Consumer>
  );
};

class Index extends Component {
  constructor(props) {
    super(props);
    this.createDebouncedFetchLiqpay();
  }
  state = {
    widgetHTML: '',
    amount: 200
  };

  fetchLiqpay = () => {
    const form = fetch('https://us-central1-volovod-488b2.cloudfunctions.net/liqPayHtml', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      body: JSON.stringify({
        action: 'pay',
        amount: this.state.amount || 200,
        currency: 'UAH',
        description: this.props.authUser.email,
        order_id: Date.now(),
        version: '3',
        server_url: 'https://us-central1-volovod-488b2.cloudfunctions.net/liqPay'
      })
    }).then(res => {
      res.text().then(html => {
        this.setState({ widgetHTML: html });
      });
    });
  };

  createDebouncedFetchLiqpay = () => {
    this.debouncedFetchLiqpay = _debounce(this.fetchLiqpay, 100);
  };

  componentDidMount() {
    this.fetchLiqpay();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.amount !== this.state.amount) {
      this.debouncedFetchLiqpay();
    }
  }

  setAmount = e => {
    this.setState({ amount: e.target.value });
  };

  render() {
    const money = _get(this, 'props.userData.money', 0);
    const abonement = _get(this, 'props.userData.abonement', 0);
    const abonementIsValid = abonement && moment(abonement.toDate()).isAfter(moment());
    const abonementButtonActive = money >= 1800 && !abonementIsValid;
    return (
      <div>
        <h2 style={{ fontSize: '24px' }}>Поповнити рахунок</h2>
        <TextField
          inputProps={{
            style: { fontSize: 24 }
          }}
          type="text"
          value={this.state.amount}
          onChange={this.setAmount}
          style={{ width: '207px', paddingBottom: 25 }}
        />
        <br />
        <div id="liqpay_checkout" dangerouslySetInnerHTML={{ __html: this.state.widgetHTML }} />
        <img src={withPrefix('cards.png')} alt="" style={{ width: '220px' }} />
        <br />
        {/*        <div className={'jumbotron well'} style={{ display: 'none' }}>
          <h4>Увага, тепер ви можете купити абонемент на 30 днів:</h4>
          <ul style={{ listStyle: 'square' }}>
            <li>Ціна 1800 грн</li>
            <li>
              Дає право відвідувати будь-які тренування, що підходять до рівня вашої підготовки, упродовж
              30 днів від дня покупки
            </li>
            <li>
              Запис на тренування поза терміном абонемента буде оплачуватися за стандартним
              тарифом
            </li>
          </ul>
                    <Button
            style={{ fontSize: 15 }}
            disabled={!abonementButtonActive}
            onClick={() => {
              this.props.trainings.buyAboniment(this.props.userData.id);
            }}
          >
            Купити абонемент
          </Button>
        </div>*/}

        <p style={{ fontSize: 12 }}>
          Дмитро Воловод надає послуги з проведення групових велотренувань й занять на
          велотренажерах — сайкл. Сплачені на сайті кошти можуть бути використані для оплати участі
          у велотренуваннях, які проводить ПП Дмитро Воловод. Середня тривалість тренування 60
          хвилин, місце проведення: вулиця В'ячеслава Липинського, 15, Київ. Увага: використання
          даного інтернет-сервісу відбувається згідно з користувацькою угодою, прочитати й
          ознайомитися з якою можна за <Link to={routes.AGREEMENT}>посиланням</Link>. Платячи за
          тренування, ви приймаєте публічний договір оферти, прочитати й ознайомитися з яким можна
          за <Link to={routes.OFFERTA}>посиланням</Link>.
          <br />
          <br />
          У разі невикористання кошти, переведені на особистий рахунок на сайті volovod.com, можна
          повернути впродовж 14 днів після поповнення. Клієнти самостійно несуть відповідальність за
          докладені фізичні зусилля.
          <br />
          Велотренування являє собою 60 хвилин інтенсивного навантаження на велотренажерах під
          постійним наглядом тренера, включає в себе консультацію з тренування й допомогу в
          налаштуванні тренажера.
        </p>

        <div className="well">
          <b>Контакти:</b>
          <div>Дмитро Воловод</div>
          <FaPhone />
          <a href="tel:+380509276677"> 0509276677</a>
          <br />
          <FaFacebook />
          <a href="https://www.facebook.com/volovodroadtraining/"> Facebook</a>
          <br />

          <FaMailBulk />
          <a href="mailto:volovodd@gmail.com"> volovodd@gmail.com</a>
        </div>
      </div>
    );
  }
}

export default withFirebase(withTrainings(IndexWrapper));
